import { Authority } from '@/shared/security/authority';

const Entities = () => import('@/entities/entities.vue');

const RexManagement = () => import('@/module/rex/rex-management.vue');
const RexImport = () => import('@/module/rex/rex-import.vue');
const RexPreImport = () => import('@/module/rex/rex-pre-import.vue');
const RexSingleItem = () => import('@/module/rex/rex-single-item.vue');

export default [
  {
    path: '/',
    component: Entities,
    children: [
      {
        path: 'rex/management',
        name: 'Rex Management',
        component: RexManagement,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'rex/import',
        name: 'Rex Import',
        component: RexImport,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'rex/preimport/:uploadFileId',
        name: 'Rex Pre Import',
        component: RexPreImport,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'rex/show/:id',
        name: 'RexItem',
        component: RexSingleItem,
        meta: { authorities: [Authority.USER] },
      },
    ],
  },
];
