import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "row justify-content-center" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          VContainer,
          { attrs: { fluid: "" } },
          [
            _c(
              VRow,
              [
                _c(
                  VCol,
                  { staticClass: "d-flex", attrs: { cols: "auto" } },
                  [
                    _c(
                      VBtn,
                      {
                        attrs: {
                          to: "/fiab/management",
                          primary: "",
                          small: "",
                          outlined: "",
                        },
                      },
                      [
                        _c(VIcon, { attrs: { left: "" } }, [
                          _vm._v("mdi-chevron-left"),
                        ]),
                        _vm._v("Retour"),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  VCol,
                  { staticClass: "text-center", attrs: { cols: "auto" } },
                  [_c("h4", [_vm._v("Gestion des templates de fiabilité")])]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          VCard,
          {
            staticClass: "mb-4 ml-10 mx-auto",
            attrs: { align: "center", flat: "" },
          },
          [
            _c(
              VForm,
              {
                ref: "importFileForm",
                staticClass: "d-flex",
                model: {
                  value: _vm.importFileFormValidity,
                  callback: function ($$v) {
                    _vm.importFileFormValidity = $$v
                  },
                  expression: "importFileFormValidity",
                },
              },
              [
                _c(VFileInput, {
                  staticStyle: { "max-width": "300px" },
                  attrs: {
                    "prepend-icon": "",
                    rules: [(v) => !!v || "Un fichier est requis"],
                    label: "Template ",
                    required: "",
                    "truncate-length": 50,
                    accept: ".docx",
                  },
                  model: {
                    value: _vm.file,
                    callback: function ($$v) {
                      _vm.file = $$v
                    },
                    expression: "file",
                  },
                }),
                _vm._v(" "),
                _c(
                  VBtn,
                  {
                    attrs: { label: "Importer", color: "primary" },
                    on: { click: _vm.importTemplate },
                  },
                  [_vm._v("Importer")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          VCard,
          {
            staticClass: "mb-4 ml-10 mx-auto",
            attrs: { align: "center", flat: "" },
          },
          [
            _c(VCardTitle, [
              _vm._v("Liste des templates précédement importés"),
            ]),
            _vm._v(" "),
            _c(VDataTable, {
              attrs: { items: _vm.items, headers: _vm.headers },
              scopedSlots: _vm._u(
                [
                  {
                    key: `item.actions`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          VIcon,
                          {
                            staticClass: "mr-2",
                            on: {
                              click: function ($event) {
                                return _vm.deleteFile(item)
                              },
                            },
                          },
                          [_vm._v(" mdi-delete ")]
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }