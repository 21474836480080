
import axios from 'axios';
import { Component, Vue, Inject, Watch } from 'vue-property-decorator';

@Component
export default class TemplateManagement extends Vue {
  items: Array<any> = [];

  headers: Array<any> = [
    {
      value: 'file',
      text: 'Nom',
    },
    {
      value: 'actions',
      text: 'Actions',
    },
  ];

  file: File = null;
  importFileFormValidity = false;

  mounted() {
    this.getTemplates();
  }

  async deleteFile(item) {
    await axios.delete(`api/fiab/template/${item.file}`);
    await this.getTemplates();
  }

  async importTemplate() {
    const formData = new FormData();

    formData.append('file', this.file);

    let result = await axios.post('api/fiab/uploadTemplate', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    await this.getTemplates();
  }

  async getTemplates() {
    let result = await axios.get('api/fiab/templates');
    this.items = result.data;
  }
}
