var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        { attrs: { to: "/project-design" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.projectDesign")
                ),
              },
            },
            [_vm._v("Project Design")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/location" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.location")),
              },
            },
            [_vm._v("Location")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/equipment-family" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.equipmentFamily")
                ),
              },
            },
            [_vm._v("Equipment Family")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/equipment" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.equipment")),
              },
            },
            [_vm._v("Equipment")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/failure-mecanism" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.failureMecanism")
                ),
              },
            },
            [_vm._v("Failure Mecanism")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/detailed-failure-mode" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.detailedFailureMode")
                ),
              },
            },
            [_vm._v("Detailed Failure Mode")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/more-detailed-failure-mode" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.moreDetailedFailureMode")
                ),
              },
            },
            [_vm._v("More Detailed Failure Mode")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }