import { Authority } from '@/shared/security/authority';

const Entities = () => import('@/entities/entities.vue');

const DccManagement = () => import('@/module/dcc/dcc-management.vue');
const DccSingleItem = () => import('@/module/dcc/dcc-single-item.vue');
const DccGroupManagement = () => import('@/module/dcc/dcc-group/dcc-group-management.vue');

export default [
  {
    path: '/',
    component: Entities,
    children: [
      {
        path: 'dcc/management',
        name: 'Dcc Management',
        component: DccManagement,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'dcc-group/management',
        name: 'Dcc Groups Management',
        component: DccGroupManagement,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'dcc/show/:id',
        name: 'DCCItem',
        component: DccSingleItem,
        meta: { authorities: [Authority.USER] },
      },
    ],
  },
];
