import { Authority } from '@/shared/security/authority';

const UploadFileView = () => import('@/shared/upload/uploadFileView.vue');
/* tslint:disable */
// prettier-ignore

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default [
  // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  {
    path: '/uploadFiles',
    name: 'uploadFiles',
    component: UploadFileView,
    meta: { authorities: [Authority.USER] },
  },
  
]
