import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const ProjectDesign = () => import('@/entities/project-design/project-design.vue');
// prettier-ignore
const ProjectDesignUpdate = () => import('@/entities/project-design/project-design-update.vue');
// prettier-ignore
const ProjectDesignDetails = () => import('@/entities/project-design/project-design-details.vue');
// prettier-ignore
const Location = () => import('@/entities/location/location.vue');
// prettier-ignore
const LocationUpdate = () => import('@/entities/location/location-update.vue');
// prettier-ignore
const LocationDetails = () => import('@/entities/location/location-details.vue');
// prettier-ignore
const EquipmentFamily = () => import('@/entities/equipment-family/equipment-family.vue');
// prettier-ignore
const EquipmentFamilyUpdate = () => import('@/entities/equipment-family/equipment-family-update.vue');
// prettier-ignore
const EquipmentFamilyDetails = () => import('@/entities/equipment-family/equipment-family-details.vue');
// prettier-ignore
const Equipment = () => import('@/entities/equipment/equipment.vue');
// prettier-ignore
const EquipmentUpdate = () => import('@/entities/equipment/equipment-update.vue');
// prettier-ignore
const EquipmentDetails = () => import('@/entities/equipment/equipment-details.vue');
// prettier-ignore
const FailureMecanism = () => import('@/entities/failure-mecanism/failure-mecanism.vue');
// prettier-ignore
const FailureMecanismUpdate = () => import('@/entities/failure-mecanism/failure-mecanism-update.vue');
// prettier-ignore
const FailureMecanismDetails = () => import('@/entities/failure-mecanism/failure-mecanism-details.vue');
// prettier-ignore
const DetailedFailureMode = () => import('@/entities/detailed-failure-mode/detailed-failure-mode.vue');
// prettier-ignore
const DetailedFailureModeUpdate = () => import('@/entities/detailed-failure-mode/detailed-failure-mode-update.vue');
// prettier-ignore
const DetailedFailureModeDetails = () => import('@/entities/detailed-failure-mode/detailed-failure-mode-details.vue');
// prettier-ignore
const MoreDetailedFailureMode = () => import('@/entities/more-detailed-failure-mode/more-detailed-failure-mode.vue');
// prettier-ignore
const MoreDetailedFailureModeUpdate = () => import('@/entities/more-detailed-failure-mode/more-detailed-failure-mode-update.vue');
// prettier-ignore
const MoreDetailedFailureModeDetails = () => import('@/entities/more-detailed-failure-mode/more-detailed-failure-mode-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'project-design',
      name: 'ProjectDesign',
      component: ProjectDesign,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-design/new',
      name: 'ProjectDesignCreate',
      component: ProjectDesignUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-design/:projectDesignId/edit',
      name: 'ProjectDesignEdit',
      component: ProjectDesignUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-design/:projectDesignId/view',
      name: 'ProjectDesignView',
      component: ProjectDesignDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'location',
      name: 'Location',
      component: Location,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'location/new',
      name: 'LocationCreate',
      component: LocationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'location/:locationId/edit',
      name: 'LocationEdit',
      component: LocationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'location/:locationId/view',
      name: 'LocationView',
      component: LocationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'equipment-family',
      name: 'EquipmentFamily',
      component: EquipmentFamily,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'equipment-family/new',
      name: 'EquipmentFamilyCreate',
      component: EquipmentFamilyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'equipment-family/:equipmentFamilyId/edit',
      name: 'EquipmentFamilyEdit',
      component: EquipmentFamilyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'equipment-family/:equipmentFamilyId/view',
      name: 'EquipmentFamilyView',
      component: EquipmentFamilyDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'equipment',
      name: 'Equipment',
      component: Equipment,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'equipment/new',
      name: 'EquipmentCreate',
      component: EquipmentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'equipment/:equipmentId/edit',
      name: 'EquipmentEdit',
      component: EquipmentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'equipment/:equipmentId/view',
      name: 'EquipmentView',
      component: EquipmentDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'failure-mecanism',
      name: 'FailureMecanism',
      component: FailureMecanism,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'failure-mecanism/new',
      name: 'FailureMecanismCreate',
      component: FailureMecanismUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'failure-mecanism/:failureMecanismId/edit',
      name: 'FailureMecanismEdit',
      component: FailureMecanismUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'failure-mecanism/:failureMecanismId/view',
      name: 'FailureMecanismView',
      component: FailureMecanismDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'detailed-failure-mode',
      name: 'DetailedFailureMode',
      component: DetailedFailureMode,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'detailed-failure-mode/new',
      name: 'DetailedFailureModeCreate',
      component: DetailedFailureModeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'detailed-failure-mode/:detailedFailureModeId/edit',
      name: 'DetailedFailureModeEdit',
      component: DetailedFailureModeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'detailed-failure-mode/:detailedFailureModeId/view',
      name: 'DetailedFailureModeView',
      component: DetailedFailureModeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'more-detailed-failure-mode',
      name: 'MoreDetailedFailureMode',
      component: MoreDetailedFailureMode,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'more-detailed-failure-mode/new',
      name: 'MoreDetailedFailureModeCreate',
      component: MoreDetailedFailureModeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'more-detailed-failure-mode/:moreDetailedFailureModeId/edit',
      name: 'MoreDetailedFailureModeEdit',
      component: MoreDetailedFailureModeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'more-detailed-failure-mode/:moreDetailedFailureModeId/view',
      name: 'MoreDetailedFailureModeView',
      component: MoreDetailedFailureModeDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
